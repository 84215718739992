/* SideBySideSlide.css */
.side-slide {
    background-color: #F5F7F8; /* Blue background */
    height: 80vh; /* Adjust the height as needed */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .slide-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    max-width: 1200px;
    padding: 20px;
    color: #090808;
  }
  
  .text-section {
    flex: 1;
    padding-right: 20px;
  }
  
  .text-section h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .text-section p {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  
  .image-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-section img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .slide-inner {
      flex-direction: column;
      text-align: center;
    }
    
    .text-section {
      padding-right: 0;
      margin-bottom: 20px;
    }

    .gn-div{

        margin-top : 120px;
    }
  }
  

  .carousel-item .img-fluid {
  
    height: 450px !important;
}

