body {
  margin: 0;
  padding:0;

  height: 100%;
  margin: 0;
 
  font-family: 'Inter', sans-serif;
  line-height: 1.6;

  
   
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;

  background-color: #fff !important;
  /* color:#821111 !important; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.h2{

  font-family: 'Inter', sans-serif;
  font-weight: 900;
  font-style: normal;
  
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Inter', sans-serif;
  /* font-family: 'Lato'; */
}

.card-title{

  font-family: 'Inter', sans-serif;
}

.dropdown-text{

  color: darkgoldenrod;
}


.gn-home-title{

  font-size: 1.5rem;
  line-height: 1.3;
  font-weight: 300;
}


.gn-middle-text{


  margin-top: 120px !important;
  text-align: left;
}

