.App {
  text-align: center;
}



body {
 
 
  font-family: 'Inter', sans-serif;
  line-height: 1.2rem;
  color: #555;

 
}

p{

  font-family: 'Inter', sans-serif;
  line-height: 1.1rem;
  color: #555;
  font-size: 1.05rem;
}

.lead{
  font-family: 'Inter', sans-serif !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.services-heading{
  text-align: left !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #821111 !important;

  min-height: 100vh; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.swiper-button-next { color: #fff ;


}



.swiper-button-next {

  /*top: var(--swiper-navigation-top-offset, 40%) !important */
}


/* .swiper {
  width: 100%;
  height: 100%;
} */
/* 
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  /* display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */ 


.heading{


  justify-content: flex-start;
  text-align: left;
  margin-left: 38px;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
  text-decoration: none;
  font-size: 20px;

}


.featuredImage{

 
  width: 100%;
 height: 320px !important;

}

.news-info{

  text-align: left;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  
}
a:news-info{

  text-decoration: none !important;
}

.news-title{

  text-align: left;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.video-iframe{

  height: 600px;
  width: 100%
}


.feat-imge{


  width: 100%;
  height: 350px;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: #fff;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}


@media only screen and (max-width: 600px) {
  .about-img{
 
   width: 100% !important
  }
 
 
 
  div.card.border-0{
 
   width: 100% !important;
  }
 
  .carousel-item img{
   width: 100% !important;
   height: 250px;
 }
 .navbar-toggler{
 
   background-color: rgb(247, 244, 244) !important;
 }
 .my-top{
   text-align: center !important;
   
 }
 .top-address{
 
   display: none !important;
 }
 
 .top-clock{
 
   display: none !important;
 }
 
 .top-phone{
 
 
   text-align: center !important;
   display: block !important;
  
 }
 .quick-links{
 
   justify-content: left !important;
 
  text-align: left;
  float: left;
 
 }
 
 .top-lock{
 
   flex-direction: row !important;
   justify-content: space-between;
   justify-content: center;
   justify-content: space-evenly;
 }
 
 .app-form{
 
   width: 100% !important
 }
 
 .bottom-dec {
 
   background-color:crimson !important;
 }
 
 .appointment-desc{
 
   top:90px !important;
 }
 
 .bottom-hours{
 
   margin-top: 18px !important;
   border-bottom: 1px solid red ;
   margin-bottom: 12px;
   padding: 18px
 
 }
 
 .container{
   --bs-gutter-x: 0.2rem !important
 }



 .swiper-button-prev, .swiper-button-next{
  position: absolute;
  top: var(--swiper-navigation-top-offset, 30%);
  width: calc(var(--swiper-navigation-size) / 54* 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px -(var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ebeff4;
}
 }


.live{

  position: relative;

  background-image: url("/src/assets/img/live.jpg");

    
 height: 455px !important;

 width: 100%;

 background-repeat: no-repeat, repeat;

 background-position: center; /* Center the image */
 background-repeat: no-repeat; /* Do not repeat the image */
 background-size: cover

}

div.live-text{
  position: absolute;
  margin-top: 72px;
  text-align: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 15%;
  bottom: 55%;
}
 

.card {
  border: none;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  transition: transform 0.2s ease-in-out;
  border: none !important
}

.card:hover {
  transform: translateY(-5px);
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  color: #821111 !important;
  
}

.card-img-top{
display: block;
  width: 50px !important;
  height: 50px!important;
  justify-content: center;
  margin: auto
}
.card-text {
  color: #555;
}



.feature-1{

  padding-top: 22px;
}

.feature-title{

  padding-top: 65px;
}

.bg-col{
display: absolute;
  background-color: #EEEEEE;
}

.modal-header {

  background-color: maroon !important;
  color: gold;
  text-align: center !important;
}

.feature-bg{
  background-image: url('../src/assets//images/ContentImageHandler855f.jpg');
  background-size: cover; /* Ensures the image covers the entire page */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat;
  height: 500px;

}

.bg-text{

width: 50%;
float: right;
margin-top: 80px;


}

.feature-title{

  font-size: 40px;
  color:#821111 !important;
}

.bg-desc{

  text-align: justify;
  padding: 14px;
  font-size: 22px;
  opacity: 13;
  color:#0F0E0E !important;
}

.bg-button{

  background-color:#821111 !important ;
  float: left;
  border: #821111 !important;
}

.bottom-text{

  display: flex;
  flex-direction: row;

  background-image: url('../src/assets//images/ContentImageHandler14be.jpg');
  background-size: cover; /* Ensures the image covers the entire page */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat;
  height: 500px;


}

.desc-text{

  margin-top: 135px !important;
}


/* .App-header {
  text-align: center;
  margin-top: 50px;
} */

button {
  font-size: 1.2rem;
  padding: 10px 20px;
}



/* Mobile Phones */

@media (min-width: 1024px){

  .mobile{

    display: none;
  }

  .desktop{

    max-height: 70px;
 
margin-bottom: 19px
  
   
  }

  /* nav{
    border-top: 12px solid #821111  !important ;
    border-bottom: 12px solid #d88b19  !important ;
   
  } */

  nav.bg-body-teritary.navbar.navbar-expand-lg.navbar-light{

    border-top: 12px solid #821111  !important ;
    border-bottom: 12px solid #FFC145  !important ;
  }


}
/* Tablets (screens between 768px and 1024px) */
@media (max-width: 1024px) and (min-width: 768px) {
  body {
    font-size: 14px;
  }

  .gn-middle-text{

    margin-top: 130px !important;
  
    text-align: left;
  }
  

  .bg-text {
    width: 100%;
    float: right;
    margin-top: 8px;
}

.desktop{
  visibility: hidden;
  display: none;
}


}

/* Mobile phones (screens less than 768px) */
@media (max-width: 767px) {
  body {
    font-size: 12px;
  }


  .gn-middle-text{

    margin-top: 10px !important;
  
    text-align: left;
  }

  .bg-text {
    width: 100%;
    float: right;
    margin-top: 8px;
}

.carousel-item .img-fluid{

  height: 300px !important;
}

.bgs{

  width: 100% !important;
}

.hero-sections1{

height: auto !important;
}

.desktop{

  visibility: hidden;
  display: none;
 
}

.bottom-dark{

  background-color: #F8FAFC;
  padding: 0px !important; 
}

a.text-success.dropdown-item{

  color: #821111 !important;
}

.feature-title {
  font-size: 14px !important;
  color: #821111 !important;
}
.bg-button.gn-button{

  margin-bottom: 12px !important;
}
}

ul.square-list {
  list-style-type: square; /* Use square bullets */
  padding-left: 20px; /* Add spacing for the bullets */
}

ul.square-list li {
  margin: 5px 0; /* Add spacing between list items */

  font-size: 22px;
}

.bg-darks{


  background-color: #821111;
  color:#ebeff4;

  padding-top: 72px;

  padding-bottom: 102px;



 
}

.let-contact{

  margin-left: 14px
}

.gn-title{
  color: #821111 !important;

  font-size: 1.5rem;
  line-height: 2.4rem;


}
.gn-secondary-title{
  color: #D39D55 !important;
  /* font-family: 'Playfair Display', serif; */
  


}



.gn-dark{

  color:#1B1833
}

.bottom-dark{

  background-color: #F5F7F8;
  padding: 50px
}

.footer-card-top.card-img-top{

  width: 100% !important;
  height: 300px !important;
}

.nav-item .dropdown a {

  color: #D39D55 !important;
}


.dropdown-text{

  color: darkgoldenrod !important;
}

.gn-button{

  color: #D39D55 !important;
  float: right;
}


.hero-section {
  background-image: url('../src/assets/images/ContentImageHandler47b2.jpg'); /* Replace with a hero image URL */
  background-size: cover;
  background-position: center;
  height: 400px;
}

.hero-sections1{

  background-image: url('../src/assets/images/ContentImageHandler47b2.jpg'); /* Replace with a hero image URL */
  background-size: cover;
  background-position: center;
  height: 400px;

}


.benefits-section h2, .features-section h2 {
  color: #002878;
  font-weight: bold;
}

.card {
  border: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.cta-section {
  background-color: #821111;
}


.features-section{

  display: flex;
 justify-content: space-evenly;
 align-items: center;

 

}

.gn-row{

 display: block;

 margin: 0 auto;
}


.bgs{

  background-color: #fff;
  opacity: 0.9;
  width: 45%;
  color:#821111 !important;
  padding: 12px;

  font-size: 12px !important;
}

.bg-checking{

  background-color: #821111 !important;
  color: #D39D55 !important;
}

.modal-buttons{

  display: flex;
  justify-content: space-between;
  justify-content: space-around;
  margin: 3px;
  justify-items: center;
}



.gn-button{

  background-color: #821111 !important;
  color: #cd8a03 !important;
  margin: 0;
  padding: 8px;
  border:#cd8a03 1px solid !important;
  display: block;
}

.gn-button-div{
display: flex;
  margin:auto;
  width: 100%;
  padding: 0;
  align-items: center;
  justify-content: center;

}

.gn-text-background{

  background-color: #F5F7F8;
}

.div-wrapper{
display: block;
  background-color: #F5F7F8;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;


}


.gn-middle-button{

  float: left;

  margin: 12px;
}



.container{

  max-width: 1200px !important;
}