.about-img{


    width: 400px;
    
}



.bgs-image{

    margin-top: 170px !important;
}


@media (max-width: 767px) {
   



.bgs-image{

    margin-top: 1px !important;
}
}  