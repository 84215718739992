/* Hero Section */
.hero-sections-mobile {
    background-image: url('../../assets/images/mobile.jpeg'); /* Replace with actual hero image */
    background-size: cover;
    background-position: center;
    color: white;
    height: 400px;
  }
  
  /* Cards Section */
  .cards-section .card {
    border: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .cards-section .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  .cards-section .card-title {
    font-size: 1.3rem;
    font-weight: bold;
    color: #821111; /* Capital One Blue */
  }
  
  /* Call to Action Section */
  .cta-section {
    background-color: #821111;
    color: white;
  }
  
  .cta-section .btn-light {
    background-color: #cd8a03;
    border: none;
    font-size: 1.2rem;
    font-weight: bold;
    color: #821111 !important;
  }
  
  .cta-section .btn-light:hover {
    background-color: #821111;
  }

  .card-button{

    background-color: goldenrod !important;
    border: none;
  }

  .credit-card-top{

    width: 100% !important;
    height: 250px !important;
  }
  

  a {
    color: goldenrod !important;
    text-decoration: none;
    float: right;
}

.gn-button{

  background-color: #821111 !important;
  color: #cd8a03 !important;
  margin: 0;
  padding: 8px;
  border:#cd8a03 1px solid !important;
  display: block;
}

.gn-button-div{
display: flex;
  margin:auto;
  width: 100%;
  padding: 0;
  align-items: center;
  justify-content: center;

}