/* ChimeSlide.css */
.chime-slide {
    position: relative;
    height: 100vh; /* Full viewport height */
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .chime-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); /* Adjust overlay opacity as needed */
  }
  
  .chime-content {
    position: relative;
    text-align: center;
    color: #fff;
    padding: 20px;
    max-width: 800px;
  }
  
  .chime-content h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .chime-content p {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }
  
  /* Optional: Responsive text scaling */
  @media (max-width: 768px) {
    .chime-content h1 {
      font-size: 2rem;
    }
    .chime-content p {
      font-size: 1.2rem;
    }
  }
  