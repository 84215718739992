

.top {position: relative;
     padding-bottom: 56.25%;
     height: 0;
     overflow: hidden;
      max-width:100%;

}

.bottom {

    position: absolute;

     top: 0;
     
     left: 0;
     
     width: 100%;
     
     height: 575px;

}


.carousel-item .img-fluid {
     /* width:100% !important; */

     height: 600px;
   }


.row-fluid{

     /* width: 100% */
}

.custom-caption{

     background-color: #FFFFFF;
     opacity: 0.6;
    width:600px;
     text-shadow: 0 0 8px rgba(255, 255, 255, 0.8); /* Soft glow */




}

@media (max-width: 767px) {


     .custom-caption {
          display: block;
          /* width:100%; */
     
          width: 300px ; 
          padding: 1px;
        
        }

}


/* img.d-block img-fluid{

     width: 1000px;
} */

   .custom-caption {
     text-align: left; /* Align text to the right */
          /* Adjust the position from the right edge */
    /* Disable Bootstrap's centering transform */

  
   }


   .gn-borders{

     background-color: #FFC145 !important;
     height: 10px;
     padding-top : -2000px !important;
     padding-bottom: 0px !important;
   }


   .gn-slide-title{


     font-size: 27px;
     color: darkred;
    
   }