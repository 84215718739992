.hero-sections {
    background-image: url('../../assets/images/cds.jpg'); /* Replace with actual hero image */
    background-size: cover;
    background-position: center;
    color: white;
    height: 470px;
  }

  /* Cards Section */
  .cards-section .card {
    border: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .cards-section .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  .cards-section .card-title {
    font-size: 1.3rem;
    font-weight: bold;
    color: #002878; /* Capital One Blue */
  }
  
  /* Call to Action Section */
  .cta-section {
    background-color: #821111;
    color: white;
  }
  
  .cta-section .btn-light {
    background-color: #ff6600;
    border: none;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .cta-section .btn-light:hover {
    background-color: #e65a00;
  }

  .card-button{

    background-color: #821111 !important;
    border: none;
  }

  .credit-card-top{

    width: 100% !important;
    height: 250px !important;
  }
  


  .cds-flexbox {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    align-items: flex-start;
  }
  
  .cd-item {
    flex: 1 1 calc(33.333% - 20px); /* 3 items per row with spacing */
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    background: #f9f9f9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
  }
  
  .cd-item:hover {
    transform: translateY(-5px);
    background: #fff;
  }
  
  @media (max-width: 768px) {
    .cd-item {
      flex: 1 1 100%; /* Full width for smaller screens */
    }
  }