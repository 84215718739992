/* Hero Section */
.hero-section {
    background-image: url('../../assets/images/loans.jpg'); /* Replace with an actual image */
    background-size: cover;
    background-position: center;
    color: white;
  }
  
  /* Features Section */
  .features-section .card {
    border: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .features-section .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  .features-section .card-title {
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  /* Loan Benefits Section */
  .loan-benefits-section ul {
    list-style-type: square;
    padding-left: 20px;
  }
  
  .loan-benefits-section ul li {
    margin-bottom: 10px;
    font-size: 1rem;
    color: #555;
  }
  
  /* Call to Action Section */
  .cta-section {
    background-color: #821111; /* GN Bank Blue */
  }
  
  .cta-section .btn-light {
    background-color: #821111;
    border: none;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .cta-section .btn-light:hover {
    background-color: #821111;
  }
  
  .bg-buttons{

    background-color: #821111 !important;

    border: none;

    color:burlywood !important


}

.bg{

    background-color: #fff;
    opacity: 0.9;
    width: 40%;
    color:#821111 !important;
    padding: 15px;
}