/* Hero Section */
.hero-sections-about  {
    background-image: url('../../assets/images/gn_bank_cover.jpeg'); /* Replace with actual hero image */
    background-size: cover;
    background-position: center;
    color: white;
    height: 500px;
  }
  
  /* Cards Section */
  .cards-section .card {
    border: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .cards-section .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  .cards-section .card-title {
    font-size: 1.3rem;
    font-weight: bold;
    color: #002878; /* Capital One Blue */
  }
  
  /* Call to Action Section */
  .cta-section {
    background-color: #821111;
    color: white;
  }
  
  .cta-section .btn-light {
    background-color: #ff6600;
    border: none;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .cta-section .btn-light:hover {
    background-color: #e65a00;
  }

  .card-button{

    background-color: #821111 !important;
    border: none;
  }

  .credit-card-top{

    width: 100% !important;
    height: 250px !important;
  }
  


.bgs2{

  background-color: #fff;
  opacity: 0.4;
  width: 50%;
  color:#821111 !important;
  padding: 12px;
margin: auto;
  font-size: 12px !important;
}