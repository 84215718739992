.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: #f1a138 !important;
}

.nav-link {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: #3a0505 !important;
    text-decoration: none;
    background: 0 0;
    border: 0;
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-style: normal;
    text-transform: uppercase;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}


nav.bg-body-teritary.navbar.navbar-expand-lg.navbar-light{

    background-color: #fff!important
}



.nav-icons {
    display: flex;
    gap: 1rem;
  }
  
  .icon {
    font-size: 1.0rem;
    cursor: pointer;
    transition: color 0.3s;
    color:#821111 !important;
  }
  
  .icon:hover {
    color: #ff6600;
  }

  .dt-login{

   margin-left: 12px;
   margin-top:-3px;
   
 
  }

  .dt-logins{

    padding-left: 3px;
    padding-right: 3px;
  }

  /* .border-bottom{

    background-color: #821111;
    
  } */

  .gn-title-customer{

    color:#3a0505 !important;
    font-weight: 600;
  }

  .portal-div{

    text-align: right;
  }